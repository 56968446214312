import { Component, OnInit, ViewContainerRef, Type, TemplateRef, Inject } from '@angular/core';
import { Overlay, OverlayRef, OverlayContainer } from '@angular/cdk/overlay';
import { CognitoService } from '../cognito.service';
import { MatDialogRef, MAT_DIALOG_DATA, MatSnackBar, MatDialog } from '@angular/material';
import { inspect } from 'util';
import { FormControl, Validators } from '@angular/forms';
import { DeleteFormComponent } from '../delete-form/delete-form.component';

export interface DialogData {
  Username: string;
  Name: string;
  Email: string;
  ViTo: string;
  AD: string;
  ClientViTo: string;
  Client47AD: string;
  isAgent: boolean;
  isBackOffice: boolean;
  isSalesManagers: boolean;
  isClients: boolean;
}

@Component({
  selector: 'app-create-form',
  templateUrl: './create-form.component.html',
  styleUrls: ['./create-form.component.scss']
})
export class CreateFormComponent implements OnInit {
  cognitoService: CognitoService;
  isAgentCheked = false;
  // boolean to see if it's a create or update
  isNew = false;

  topLabel = 'Create utente';
  buttonText = 'Create';
  creationError = '';

  // controller for email
  isEmailInvalid = false;

  idRequired = false;

  // email validator
  email = new FormControl(' ', [Validators.email]);
  snackBar: MatSnackBar;
  // data mock for user data before update;
  dataBefore: DialogData;

  constructor( cognitoService: CognitoService,  public dialogRef: MatDialogRef<CreateFormComponent>,
               @Inject(MAT_DIALOG_DATA) public data: DialogData, snackBar: MatSnackBar, public dialog: MatDialog) {
    this.cognitoService = cognitoService;
    this.snackBar = snackBar;

   }

  ngOnInit() {
    if (this.data.Username != '') {
      this.isNew = false;
      this.topLabel = 'Modifica utente';
      this.buttonText = 'Salva';

      // deep copy of the object data
      this.dataBefore = JSON.parse(JSON.stringify(this.data));
    } else {
      this.isNew = true;
      this.topLabel = 'Crea utente';
      this.buttonText = 'Crea';

      // SET DEFAULT VALUES FOR CHECKBOX
      this.data.isAgent = false;
      this.data.isBackOffice = false;
      this.data.isSalesManagers = false;
      this.data.isClients = false;
    }
    // console.log(inspect(this.data, false, null));
  }

  async createUser() {
    // check if agent is checke and one id is comiled either ViTo or 47AD
    console.log(this.data.isAgent);
    console.log(this.data.ViTo);
    if (this.data.isAgent && (this.data.ViTo === '' && this.data.AD === '')) {
      this.creationError = 'L\'utente Agent deve avere un id ViTo o 47AD';
    } else {
      if (this.isNew) {
        try {
           await this.cognitoService.addUser(this.data);
           console.log('isNew');
           this.snackBar.open('Utente ' + this.data.Username + ' creato', '', {
            duration: 3000
          });
           this.dialogRef.close();
        } catch (ex) {
          this.creationError = 'Username o email già usati per altro utente';
        }

      } else {
        // update
        await this.cognitoService.updateUser(this.data, this.dataBefore);
        this.snackBar.open('Utente ' + this.data.Username + ' modificato con successo', '', {
          duration: 3000
        });
        this.dialogRef.close();
      }
    }



  }

  // invalid email maessage
  getErrorMessage() {
    // if email is not valid and it contains something different from '' as defined on validator above
    return this.email.hasError('email') ? 'Email non corretta' : '';
  }

  agentChecked() {
    // console.log(this.data.isAgent);
    this.data.isAgent = !this.data.isAgent;
    // console.log(this.data.isAgent);
    if (this.data.isAgent && this.data.ViTo === '' && this.data.AD === '') {
      this.creationError = 'Agent deve avere almeno un id assegnato';
      this.idRequired = true;
    } else {
      this.creationError = '';
      this.idRequired = false;
    }
  }

  agentIdChange() {

    if ((this.data.ViTo !== ''  || this.data.AD !== '' ) && this.idRequired) {
      this.idRequired = false;
      this.creationError = '';
    }

    if ((this.data.ViTo === '' && this.data.AD === '') && this.data.isAgent) {
      this.creationError = 'Agent deve avere almeno un id assegnato';

      // because it can be false as above
      this.idRequired = true;
    }
  }

  async deleteUser() {

    console.log('in remove');
    const dialogRef = this.dialog.open(DeleteFormComponent, {
      width: '500px',
      data: this.data,
    });

    dialogRef.afterClosed().subscribe(async result => {
      this.dialogRef.close();
    });
  }

}
