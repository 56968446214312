import { Injectable } from "@angular/core";
import {
  CanActivate,
  ActivatedRouteSnapshot,
  RouterStateSnapshot,
  UrlTree,
  Router
} from "@angular/router";
import { Observable } from "rxjs";
import { Auth } from "aws-amplify";
import { AuthService } from "../auth.service";

@Injectable({
  providedIn: "root"
})
export class AuthGuardService implements CanActivate {
  constructor(private router: Router, private authService: AuthService) {}

  async canActivate(next: ActivatedRouteSnapshot, state: RouterStateSnapshot): Promise<boolean | UrlTree> {
    try {
        let path = next.routeConfig.path;
        let user = await Auth.currentAuthenticatedUser();
        if(!user) {
            this.router.navigate(['login']);
            return false;
        } else {
            return true;
        }
    } catch {
        return false;
    }
  }
}
