// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
  production: false,
  // baseURL: 'YOUR_BASE_URL',
  region: 'eu-central-1',
  userPoolId: 'eu-central-1_UrNEjJuMP',
  userPoolWebClientId: '54l59d3mt7nnltbmg6u8ng4ms2',
  roleArn: 'arn:aws:iam::912431438859:role/CognitoAdminRole',
  identityPoolId: 'eu-central-1:bf215cc9-41c2-42a4-9e87-8bc74505fd08',
};

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/dist/zone-error';  // Included with Angular CLI.
