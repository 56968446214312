import { Component, OnInit, Inject } from '@angular/core';
import { DialogData } from '../create-form/create-form.component';
import { MatDialog, MAT_DIALOG_DATA, MatDialogRef } from '@angular/material';
import { CognitoService } from '../cognito.service';

@Component({
  selector: 'app-delete-form',
  templateUrl: './delete-form.component.html',
  styleUrls: ['./delete-form.component.scss']
})
export class DeleteFormComponent implements OnInit {

  constructor(@Inject(MAT_DIALOG_DATA) public data: DialogData, public cognitoService: CognitoService,
              public dialogRef: MatDialogRef<DeleteFormComponent>) {

   }

  ngOnInit() {
  }

  async confirmDeleteUser() {
     await this.cognitoService.deleteUser(this.data);
     this.dialogRef.close();
  }

  goBack() {
    this.dialogRef.close();
  }


}
