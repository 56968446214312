import { Injectable } from '@angular/core';
import Auth from '@aws-amplify/auth';
import { Hub } from '@aws-amplify/core';
import { BehaviorSubject } from 'rxjs';
import { map, take } from 'rxjs/operators';
import { HttpRequest } from '@angular/common/http';

export interface AuthState {
  isLoggedIn: boolean;
  username: string | null;
  id: string | null;
  email: string | null;
  groups: string[] | null;
}

const initialAuthState = {
  isLoggedIn: false,
  username: null,
  id: null,
  email: null,
  groups: null
};

// Token
export interface TokenAuthState {
  jwtToken: string | null;
}

const initialTokenAuthState = {
  jwtToken: null
};


// User for role
export interface UserAttributes {
  groups: string[];
}
const initialUserAttr = {
  groups: null
};

@Injectable({
  providedIn: 'root'
})
export class AuthService {
  private readonly _authState = new BehaviorSubject<AuthState>(
    initialAuthState
  );

  private readonly _tokenState = new BehaviorSubject<TokenAuthState>(
    initialTokenAuthState
  );

  private _userState = new BehaviorSubject<UserAttributes>(
    initialUserAttr
  );

  /** AuthState as an Observable */
  readonly auth$ = this._authState.asObservable();

  readonly authToken$ = this._tokenState.asObservable();

  /** Observe the isLoggedIn slice of the auth state */
  readonly isLoggedIn$ = this.auth$.pipe(map(state => state.isLoggedIn));

  // UserAttr
  userAttr$ = this._userState.asObservable();

  constructor() {
    // Get the user on creation of this service
    Auth.currentAuthenticatedUser().then(
      (user: any) => {
        this.setUser(user);
        // Auth.currentSession().then(
        //   (token: any) => {
            
        //     this.setToken(token);
        //   },
        //   _err => this._tokenState.next(initialTokenAuthState)
        // );
      },
      _err => this._authState.next(initialAuthState)
    );

    // Use Hub channel 'auth' to get notified on changes
    Hub.listen('auth', ({ payload: { event, data, message } }) => {
      if (event === 'signIn') {
        // On 'signIn' event, the data is a CognitoUser object
        //this.userAttributes();
        this.setUser(data);
        //this.setToken(data);
      } else {
        this._authState.next(initialAuthState);
      }
    });
  }

  // private setToken(token: any) {
  //   let jwtToken = token.getIdToken().getJwtToken();
  //   this._tokenState.next({ jwtToken });
  // }

  private setUser(user: any) {
    if (!user) {
      return;
    }
    
    let groups = user.signInUserSession.idToken.payload['cognito:groups'];
    const {
      attributes: { sub: id, email },
      username
    } = user;

    this._authState.next({ isLoggedIn: true, id, username, email, groups });
  }

  public signOut(){
    return Auth.signOut({ global: true });
  }

  session() {
    return this.authToken$;
  }

  cachedRequests: Array<HttpRequest<any>> = [];

  public collectFailedRequest(request): void {
    this.cachedRequests.push(request);
  }
  
  async userAttributes() {
    let user = await Auth.currentAuthenticatedUser();
    let groups: string[] = user.signInUserSession.idToken.payload['cognito:groups'];
    this._userState.next({ groups });
  }
}