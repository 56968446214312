import { environment } from './environments/environment';

export const awsconfig = {
    Auth: {
        region: environment.region,
        userPoolId: environment.userPoolId,
        userPoolWebClientId: environment.userPoolWebClientId,
        identityPoolId: environment.identityPoolId,
    }
};
