import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { AuthService } from './auth.service';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent implements OnInit {
  
  title = 'angular-cognito-default';
  isLogged:boolean = false;
  user: { id: string; username: string; email: string };
  
  signUpConfig = {
    header: 'Registrazione',
    hideAllDefaults: true,
    defaultCountryCode: '1',
    signUpFields: [
      {
        label: 'Username',
        key: 'username',
        required: true,
        displayOrder: 1,
        type: 'string',
      },
      {
        label: 'Nome',
        key: 'name',
        required: true,
        displayOrder: 2,
        type: 'string',
      },
      {
        label: 'Cognome',
        key: 'family_name',
        required: true,
        displayOrder: 3,
        type: 'string'
      },
      {
        label: 'Email',
        key: 'email',
        required: true,
        displayOrder: 4,
        type: 'string',
      },
      {
        label: 'Password',
        key: 'password',
        required: false,
        displayOrder: 5,
        type: 'password',
        custom: true
      }
    ]
  }

  constructor(private _router: Router, private authService: AuthService) {
    
  }

  ngOnInit() {
    this.authService.isLoggedIn$.subscribe(
      async isLoggedIn => {
        this.isLogged = isLoggedIn
        if(this.isLogged == true) {
          this._router.navigateByUrl('home');
        } else {
          this._router.navigateByUrl('/')
        }
      }
    );

    this.authService.auth$.subscribe(({ id, username, email }) => {
      this.user = { id, username, email };
    });
  }
}
