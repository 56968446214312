import { Component, OnInit, TemplateRef, ViewChild, Output, EventEmitter, Inject } from '@angular/core';
import { CognitoService } from '../cognito.service';
import { Auth } from 'aws-amplify';
import {MatTableDataSource, MatButton, MatPaginator, MatDialog, MatDialogRef, MAT_DIALOG_DATA} from "@angular/material";
import { inspect } from 'util';
import { Observable } from 'rxjs';
import {Overlay, OverlayConfig, OverlayRef, ScrollStrategyOptions, BlockScrollStrategy} from '@angular/cdk/overlay';
import { ComponentPortal } from '@angular/cdk/portal';
import { CreateFormComponent } from '../create-form/create-form.component';
import { UserType } from 'aws-sdk/clients/workdocs';
import { UsersListType } from 'aws-sdk/clients/cognitoidentityserviceprovider';



@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.scss']
})



export class HomeComponent implements OnInit {
  cognitoService: CognitoService;
  overlay: Overlay;
  showCreateForm: Boolean = false;
  displayedColumns: string[] = ['Username', 'Name', 'Email', 'Agents', 'BackOffice', 'SalesManagers', 'Clients', 'Id Agente ViTo', 'Id Agente AD', 'Id Client ViTo', 'Id Client 47AD', 'Edit'];
  // groups from cognito
  userGroups = {Agents : 'Agents', BackOffice: 'BackOffice', SalesManagers: 'SalesManagers', Clients: 'Clients'}
  dataSource = new MatTableDataSource();
  usersInSales: UsersListType;
  usersInAgents : UsersListType;
  usersInBackOffice : UsersListType;
  usersInClients: UsersListType;
  icons = {Search: 'search', Close: 'close'};

  // users list complete to cache and the searched one
  completeUsersList: any;
  searchedUsersList: UsersListType;

  hasNoIds : boolean = false;
  hasVito : boolean = false;
  hasAD : boolean = false;

  searchQuery = '';
  iconToolbar = '';

  @ViewChild(MatPaginator, {static: true}) paginator: MatPaginator;

  constructor(cognitoService: CognitoService, public dialog: MatDialog) {
    this.cognitoService = cognitoService;
    this.searchedUsersList = [];
    this.iconToolbar = this.icons.Search;

  }

  async ngOnInit() {
    // get all users from sales
    const usersInSales = await this.cognitoService.getUsersInGroupList(this.userGroups.SalesManagers);
    this.usersInSales = usersInSales.Users;

    // get all users from agents
    const usersInAgents = await this.cognitoService.getUsersInGroupList(this.userGroups.Agents);
    this.usersInAgents = usersInAgents.Users;

    // get all users from backoffice
    const usersInBackOffice = await this.cognitoService.getUsersInGroupList(this.userGroups.BackOffice);
    this.usersInBackOffice = usersInBackOffice.Users;

    // get all users from clients
    const usersInClients = await this.cognitoService.getUsersInGroupList(this.userGroups.Clients);
    this.usersInClients = usersInClients.Users;

    // print users in the table
    this.printUsers().catch(console.error);
  }

  async printUsers() {

    const users = await this.cognitoService.getUsers();
    // console.log(inspect(users, false, null));
    this.dataSource.data = users;
    this.completeUsersList = users;
    this.dataSource.paginator = this.paginator;

  }

  hasIds(element){
   // if user is a salesmanager
   if(this.isUserInGroup(this.userGroups.Agents,element)){
     // user is a salesmanager
    if(element.Attributes.find(this.getVito) === undefined){

        // if it's her Vito is not present
        if( element.Attributes.find(this.getIdAD) === undefined){
            // if it's here neither Vito or Ad has been set
            // console.log(true);
            return true;
        }
    }
  }
   // console.log(false);
   return false;
  }

  getName(attr) {

    if (attr.Name === "name") {
      return attr;
    }
  }

  getEmail(attr){
    if(attr.Name === "email"){
      return attr.Value;
    }
  }

  getVito(attr){
    if(attr.Name === 'custom:salesperson_id_vito'){
        return attr.Value;
    }
  }
  getIdAD(attr){
    if(attr.Name === 'custom:salesperson_id_47ad'){
        return attr.Value;
    }
  }

  getClientIdViTo(attr){
    if(attr.Name === 'custom:client_id_vito'){
      return attr.Value;
    }
  }

  getClientIdAD(attr){
    if(attr.Name === 'custom:client_id_47ad'){
      return attr.Value;
    }
  }

  // given groupname and user return if the user is present in that group
  isUserInGroup(group, user){
    // console.log(group);
    let groupToSearch;
    switch (group){
      case this.userGroups.Agents : groupToSearch = this.usersInAgents; break;
      case this.userGroups.BackOffice : groupToSearch = this.usersInBackOffice; break;
      case this.userGroups.SalesManagers : groupToSearch = this.usersInSales; break;
      case this.userGroups.Clients : groupToSearch = this.usersInClients; break;
    }

    let found = false;
    groupToSearch.forEach(element => {
     // console.log("element.Username = "+ element.Username+" user.Username = "+user.Username);
      if (element.Username === user.Username){
       // console.log(true);
        found = true;
      }
    });

    // if user isn't in the group return false
    return found;
  }

  openForm(element) {
    console.log(inspect(element, false, null));
    let username = '';
    let name = '';
    let email = '';
    let vito = '';
    let ad = '';
    let clientVito = '';
    let client47AD = '';
    let isAgent = false;
    let isBackOffice = false;
    let isSalesManagers = false;
    let isClients = false;

    if (element === undefined) {

    } else {
      const elemName = element.Attributes.find(this.getName);
      const elemEmail = element.Attributes.find(this.getEmail);
      const elemVito =  element.Attributes.find(this.getVito);
      const elemAD = element.Attributes.find(this.getIdAD);
      const elemClientVito = element.Attributes.find(this.getClientIdViTo);
      const elemClient47AD = element.Attributes.find(this.getClientIdAD);

      // get all infos from the user to diaply in the modal
      username =  element.Username;
      name =  (elemName === undefined) ? '' : elemName.Value ;
      email =  (elemEmail  === undefined) ? '' : elemEmail.Value;
      vito = (elemVito === undefined) ? '' : elemVito.Value;
      ad =  ( elemAD === undefined) ? '' : elemAD.Value;
      clientVito = ( elemClientVito === undefined) ? '' : elemClientVito.Value;
      client47AD = ( elemClient47AD === undefined) ? '' : elemClient47AD.Value;

      isAgent = this.isUserInGroup(this.userGroups.Agents, element);
      isBackOffice = this.isUserInGroup(this.userGroups.BackOffice, element);
      isSalesManagers = this.isUserInGroup(this.userGroups.SalesManagers, element);
      isClients = this.isUserInGroup(this.userGroups.Clients, element);
    }
    const dialogRef = this.dialog.open(CreateFormComponent, {
      width: '520px',
      data: { Username: username,
              Name: name,
              Email: email,
              ViTo: vito,
              AD : ad,
              ClientViTo: clientVito,
              Client47AD: client47AD,
              isAgent: isAgent,
              isBackOffice: isBackOffice,
              isSalesManagers: isSalesManagers,
              isClients: isClients,
            }
    });

    dialogRef.afterClosed().subscribe(async result => {
      // after close reload table data to get updated info about users
      console.log('Before update');
      await this.printUsers();
      console.log('After update');
    });
  }

  async signOut() {
    try {
      await Auth.signOut({ global: true });
    } catch (error) {
      console.log('error signing out: ', error);
    }
  }

  // this method is called on every keypress on the searchbar
  async searchUser() {
    console.log(this.searchedUsersList);

    this.iconToolbar = (this.searchQuery.length > 0 ) ? this.icons.Close : this.icons.Search ;


    const re = this.searchQuery;
    this.dataSource.data.forEach(user => {
        // if username, name or email matches with the search
        if (user['Username'].match(re)) {
          this.searchedUsersList.push(user);
          console.log(this.searchedUsersList);
        }
      });
    this.dataSource.data = this.searchedUsersList;

    this.searchedUsersList = [];

    if (this.searchQuery.length === 0) {
      this.dataSource.data = this.completeUsersList;
    }
  }

  resetSearch() {
    this.dataSource.data = this.completeUsersList;
    this.searchQuery = '';
    this.iconToolbar = this.icons.Search;
  }

}
