import { HttpEvent, HttpHandler, HttpInterceptor, HttpRequest } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { Auth } from "aws-amplify";
import { from } from "rxjs";
import { Observable } from "rxjs/Observable";
import { switchMap } from "rxjs/operators";
import { AuthService } from "./auth.service";

@Injectable()
export class TokenInterceptor implements HttpInterceptor {
  constructor(public auth: AuthService) {}
  intercept(request: HttpRequest<any>, next: HttpHandler ): Observable<HttpEvent<any>> {
    return from(this.getToken()).pipe(
      switchMap(token => {
        const headers = request.headers
          .set("Authorization", "Bearer " + token)
          .append("Content-Type", "application/json; charset=utf-8")
          .append("Accept", "application/json");
        const requestClone = request.clone({
          headers
        });
        return next.handle(requestClone);
      })
    );
  }

  async getToken(): Promise<string> {
    let session = await Auth.currentSession();
    let token = session.getIdToken().getJwtToken();
    return token;
  }
}
