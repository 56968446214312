import { Injectable } from '@angular/core';
import { CognitoIdentityServiceProvider } from 'aws-sdk';
import { Auth } from 'aws-amplify';
import { environment } from 'src/environments/environment';
import { inspect } from 'util';
import { DialogData } from './create-form/create-form.component';
import { ThrowStmt } from '@angular/compiler';

@Injectable({
  providedIn: 'root'
})
export class CognitoService {

  constructor() { }

  private cognitoIDP?: CognitoIdentityServiceProvider;

  // RequestParams to perform cognito calls
  private addUserParams: CognitoIdentityServiceProvider.AdminCreateUserRequest;
  private listGroupsParams: CognitoIdentityServiceProvider.ListGroupsRequest;
  private listUsersinGroupParams: CognitoIdentityServiceProvider.ListUsersInGroupRequest;
  private updateUserAttributesParams: CognitoIdentityServiceProvider.AdminUpdateUserAttributesRequest;
  private adminAddUserToGroupParams: CognitoIdentityServiceProvider.AdminAddUserToGroupRequest;
  private adminRemoveUserFromGroupParams: CognitoIdentityServiceProvider.AdminRemoveUserFromGroupRequest;
  private adminDeleteUserParams: CognitoIdentityServiceProvider.AdminDeleteUserRequest;

  private async getCognitoIDP() {
   /* if (this.cognitoIDP === undefined) {
      return this.cognitoIDP;
    }*/
    const credentials = await Auth.currentCredentials();
    const cognito = new CognitoIdentityServiceProvider({
      region: environment.region,
      credentials,
    });
    this.cognitoIDP = cognito;
    return cognito;
  }

  async getUsers(): Promise<CognitoIdentityServiceProvider.UserType[]> {
    const cognito = await this.getCognitoIDP();
    console.log(cognito);
    const response = await cognito.listUsers({
      UserPoolId: environment.userPoolId,
    }).promise();
    return response.Users;
  }

  async addUser(data: DialogData){
    console.log(data);
    this.addUserParams = {
      Username: data.Username,
      UserPoolId : environment.userPoolId,

      UserAttributes: [
        {Name:"email", Value: data.Email},
        {Name:"custom:salesperson_id_vito", Value:data.ViTo},
        {Name:"name", Value: data.Name},
        {Name:"custom:salesperson_id_47ad", Value:data.AD},
        {Name: 'custom:client_id_vito', Value: data.ClientViTo},
        {Name: 'custom:client_id_47ad', Value: data.Client47AD},
      ],
      ForceAliasCreation: false,    // to avoid different user with the same email
    }
    const cognito = await this.getCognitoIDP();
    // add user to cognito
    const response = await cognito.adminCreateUser(this.addUserParams).promise();
    console.log(inspect(response, false, null));

    // add user to selected groups
    if ( data.isAgent) { this.addUserToGroup(data.Username, 'Agents'); }
    if ( data.isBackOffice) { this.addUserToGroup(data.Username, 'BackOffice'); }
    if ( data.isSalesManagers) { this.addUserToGroup(data.Username, 'SalesManagers'); }
    if ( data.isClients) { this.addUserToGroup(data.Username, 'Clients'); }

    return response;
  }

  async updateUser(data: DialogData, dataBefore: DialogData) {

    console.log(data);
    console.log(dataBefore);
    const cognito = this.getCognitoIDP();

    this.updateUserAttributesParams = {
      UserPoolId: environment.userPoolId,
      Username: data.Username,
      UserAttributes: [
        {Name: 'name', Value: data.Name},
        {Name: 'email', Value: data.Email},
        {Name: 'custom:salesperson_id_vito', Value: data.ViTo},
        {Name: 'custom:salesperson_id_47ad', Value: data.AD},
        {Name: 'custom:client_id_vito', Value: data.ClientViTo},
        {Name: 'custom:client_id_47ad', Value: data.Client47AD},
      ]
    };

    const response = await (await cognito).adminUpdateUserAttributes(this.updateUserAttributesParams).promise();
    // update user groups

    // check which group has been modified
    // if the group has been modified check if it's added or removed from the group
    if (data.isAgent !== dataBefore.isAgent) {
      if (data.isAgent) {
        await this.addUserToGroup(data.Username, 'Agents');
      } else {
        await this.removeUserFromGroup(data.Username, 'Agents');
      }
    }

    // check which group has been modified
    if(data.isBackOffice !== dataBefore.isBackOffice) { 
      if(data.isBackOffice){
        await this.addUserToGroup(data.Username, 'BackOffice');
      } else {
        await this.removeUserFromGroup(data.Username, 'BackOffice');
      }
    }

    if(data.isSalesManagers !== dataBefore.isSalesManagers) { 
      if(data.isSalesManagers){
        await this.addUserToGroup(data.Username, 'SalesManagers');
      } else {
        await this.removeUserFromGroup(data.Username, 'SalesManagers');
      }
    }

    if(data.isClients !== dataBefore.isClients) { 
      if(data.isClients){
        await this.addUserToGroup(data.Username, 'Clients');
      } else {
        await this.removeUserFromGroup(data.Username, 'Clients');
      }
    }

  }

  // method to add user to group
  async addUserToGroup(username: string, group: string){
    this.adminAddUserToGroupParams = {
      GroupName : group,
      UserPoolId: environment.userPoolId,
      Username: username,
    };

    const cognito = this.getCognitoIDP();
    const response = await (await cognito).adminAddUserToGroup(this.adminAddUserToGroupParams).promise();

    return response;
  }

   // method to remove user from group
   async removeUserFromGroup(username: string, group: string) {
    this.adminRemoveUserFromGroupParams = {
      GroupName : group,
      UserPoolId: environment.userPoolId,
      Username: username,
    };

    const cognito = this.getCognitoIDP();
    const response = await (await cognito).adminRemoveUserFromGroup(this.adminRemoveUserFromGroupParams).promise();

    return response;
  }

  // method to retrieve a list of groups
  async getGroupsList() {
    const cognito = await this.getCognitoIDP();
    this.listGroupsParams = {
      UserPoolId: environment.userPoolId,
    };
    const response = await cognito.listGroups(this.listGroupsParams).promise();
    return response;
  }

  // method to retrieve all the users in a specific group
  async getUsersInGroupList(groupName: string) {
    const cognito = await this.getCognitoIDP();
    this.listUsersinGroupParams = {
      UserPoolId: environment.userPoolId,
      GroupName: groupName,
    };
    const response = await cognito.listUsersInGroup(this.listUsersinGroupParams).promise();
    return response;
  }

  // method to delete user and to remove it from its groups
  async deleteUser(data: DialogData) {
    const cognito = await this.getCognitoIDP();
    this.adminDeleteUserParams = {
      UserPoolId: environment.userPoolId,
      Username: data.Username,

    };
    // call to remove user
    const resonse = await cognito.adminDeleteUser(this.adminDeleteUserParams).promise();

    // check user groups and remove where it's present
    if (data.isAgent) { this.removeUserFromGroup(data.Username, 'Agents'); }
    if (data.isBackOffice) { this.removeUserFromGroup(data.Username, 'BackOffice'); }
    if (data.isClients) { this.removeUserFromGroup(data.Username, 'Clients'); }
    if (data.isSalesManagers) { this.removeUserFromGroup(data.Username, 'SalesManagers'); }

  }
}
